.contactWrapper {
    padding-bottom: 100px;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd img {
    width: 30px;
    height: 30px;
    object-fit: scale-down;
    margin-right: 15px;
    filter: brightness(0.2);
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr {
    text-align: left;
    margin-bottom: 55px;
    line-height: 32px;
    font-size: 18px;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr a {
    color: black;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr.mlWrpr a {
    color: var(--secColor);
    font-weight: 500;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr p {
    margin-bottom: 0px;
    font-weight: 500;
}

.contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr p span {
    font-weight: 600;
}

.contactWrapper .contactFormWrapper {
    background: white;
    box-shadow: 0 0 10px #00000026;
    border-radius: 10px;
    padding: 40px 50px;
    width: 100%;
    max-width: 680px;
}

.contactWrapper .contactFormWrapper .primButton {
    width: 100%;
    margin-top: 20px;
}

.contactWrapper .contactFormWrapper .primButton button {
    width: 100%;
    background: var(--secColor);
    border-radius: 8px;
    height: 55px;
    border: none;
}

.contactWrapper .contactFormWrapper .primButton .MuiButton-label {
    font-size: 15px;
}

.contactFormWrapper .MuiFormHelperText-root {
    color: red;
}

@media (max-width: 1200px) {
    .contactWrapper .contactFormWrapper {
        max-width: 600px;
    }
}

@media (max-width: 991px) {
    .contactWrapper {
        flex-wrap: wrap-reverse;
        justify-content: center !important;
    }

    .contactWrapper .cntcInfoWrpr {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 10px;
        width: 100%;
    }

    .contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr.mlWrpr a,
    .contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr p {
        font-size: 15px;
    }

    .contactWrapper .cntcInfoWrpr .flxWrpGrd img {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .contactWrapper .cntcInfoWrpr .flxWrpGrd {
        flex-wrap: wrap;
        justify-content: center;
    }
    .contactWrapper .cntcInfoWrpr .flxWrpGrd .rtInfoTxtWrpr {
        width: 100%;
        text-align: center;
    }
}
