* {
    box-sizing: border-box;
}

.Signup {
    display: flex;
    justify-content: center;
    text-align: center;
}

.Signup .Registration-Banner {
    background-color: white;
}

.Signup .signup-Text h1 {
    height: 35px;
    color: #000;
    font-size: 26px;
    font-weight: 600;
}

.Signup .signup-inputfields {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 15px 0;
}

.Signup .Registration-Banner {
    padding-top: 25px;
}

.Signup .logo-signup {
    margin-bottom: 25px;
}

.Signup .logo-signup img {
    width: 300px;
    object-fit: contain;
}

.Signup .registration-inp {
    margin: 0 0 20px 0;
}

.Signup .signup-btn .primButton .MuiButton-root {
    width: 191px;
    text-transform: none;
}

.Signup .intl-tel-input input {
    width: 100%;
}

.Signup .intl-tel-input {
    width: 100%;
}

.Signup .signin-Instead {
    margin-top: 25px;
}

.Signup .signin-Instead a {
    border-bottom: 1px solid #000;
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.Signup .errorMessage-1 {
    color: red;
    margin-top: 20px;
    text-align: left;
}

.Signup .errorMessage-2 {
    color: red;
    margin-top: 20px;
    text-align: left;
}

.signup-page .auth-wrapper {
    max-width: 580px;
}

.signup-inputfields label + .MuiInput-formControl {
    margin-top: 0;
}

.signup-inputfields label.MuiInputLabel-formControl {
    transform: translate(0, 4px) scale(1);
}

.signup-inputfields .MuiSelect-select.MuiSelect-select {
    line-height: 32px;
    height: 32px;
    padding: 0;
}

.signup-inputfields label.MuiInputLabel-shrink {
    transform: translate(0, -8px) scale(1);
    color: var(--secColor);
    font-weight: 400;
    font-size: 12px;
    z-index: 99;
}

.signup-page .intlIptWrpr label {
    text-align: left;
    top: -11px;
    font-size: 12px;
    margin-bottom: 0;
    position: absolute;
    color: var(--secColor);
    font-weight: 400;
    z-index: 999;
    font-family: 'Roboto', sans-serif;
}

.signup-page .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
    background: #fff;
}

.signup-page .intl-tel-input .flag-container .arrow {
    padding: 10px 0;
}

.registration-inp .phone-number-error.errorTxt,
.registration-inp .phone-number-error {
    text-align: left;
    color: red;
    font-size: 0.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    position: absolute;
    bottom: -26px;
}

.registration-inp .phone-number-error.errorTxt {
    bottom: -48px;
}

.signup-page .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    line-height: 32px;
}

.signup-page .intl-tel-input {
    max-width: 100%;
}

.signup-page .intlIptWrpr {
    height: 32px;
    padding-top: 0;
}

.signup-page .intlIptWrpr .intl-tel-input .country-list {
    z-index: 999;
}

.signup-page .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type='text'],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type='tel'] {
    height: 32px;
    outline: none;
    max-width: 100%;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type='text'],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type='tel'] {
    outline: none !important;
}

@media (max-width: 992px) {
    .Signup {
        display: grid;
        grid-template-columns: 50% 1fr;
    }
    .custom-container-signup {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
@media (max-width: 860px) {
    .Signup {
        display: grid;
        grid-template-columns: 30% 1fr;
    }
}
@media (max-width: 630px) {
    .Signup {
        grid-template-columns: 1fr;
    }
    .Login {
        display: grid;
        text-align: center;
        grid-template-columns: 1fr;
        justify-content: normal;
    }
    .Signup .Registration-Banner {
        padding-bottom: 25px;
        padding-top: 100px;
    }
    .coinImg img {
        height: 100px;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .coinImg {
        position: relative;
        display: block;
        height: 100px;
        width: 100%;
    }
    .coinImg:after {
        content: '';
        position: absolute;
        background: #0000002e;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 5;
        height: 100%;
    }
    .Signup .logo-signup {
        width: 230px;
        height: 65px;
        z-index: 9999;
        display: block;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 150px;
        left: 0;
        right: 0;
        background: white;
        border-radius: 10px;
        padding: 10px;
    }
    .Signup .logo-signup img {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .signup-page .intlIptWrpr {
        margin-bottom: 35px;
    }
}
@media (max-width: 425px) {
    .Signup .registration-inp {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .Signup .signup-inputfields {
        display: grid;
    }
}
