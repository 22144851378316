.Dashboard .custom-Components {
    /* padding-top: 30px; */
    /* margin-left: 40px; */
    width: calc(100% - 220px);
}

.Dashboard .custom-Components .Active-Auction-Tabs {
    display: flex;
    margin-left: 25px;
}
.timer-dashboard .justify-content-center {
    justify-content: left !important;
}

.Auction-Table .timer-dashboard .closed {
    padding-bottom: 10px;
    font-size: 16px;
}

.vwBtnWrpr .dashActiveTable .secButton + .secButton,
.dashActiveTable .secButton:nth-child(2) {
    margin-left: 0;
}

.dashActiveTable {
    grid-gap: 15px;
    gap: 5px;
    display: grid;
    justify-content: flex-start;
}

.timer-dashboard .closed {
    padding-bottom: 55px;
}

.Dashboard .custom-Components .Active-Auction-Tabs a:nth-of-type(1) {
    color: red;
    text-transform: none;
    border-bottom: 2px solid red;
}
.Dashboard .table th:nth-of-type(1) {
    padding-left: 30px;
}
.Dashboard .table .thead-light th {
    font-family: var(--fontFamily2);
    font-size: 15px;
    font-weight: 500;
    background-color: #ececec;
    border: none;
    color: black;
    vertical-align: middle;
    line-height: 1.25;
}
.Dashboard .custom-Components .Active-Auction-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Active-Auction-Tabs a {
    margin-right: 50px;
    font-weight: 600;
    color: #a8a7a7;
}

.Auction-Table .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
}

.Dashboard .active-pgn .MuiPagination-root .MuiPagination-ul {
    width: max-content;
    margin: 20px auto;
}
.Dashboard .buynow-pgn .MuiPagination-root .MuiButtonBase-root,
.Dashboard .lost-pgn .MuiPagination-root .MuiButtonBase-root,
.Dashboard .pagno .MuiPagination-root .MuiButtonBase-root,
.Dashboard .won-pgn .MuiPagination-root .MuiButtonBase-root,
.Dashboard .active-pgn .MuiPagination-root .MuiButtonBase-root {
    width: auto;
    max-width: 100px;
    min-width: 30px;
    height: 30px;
}

.timeCnt .timerCnt {
    padding-top: 0;
}
.Auction-Table .timeTitle {
    display: none;
}
.Auction-Table .Days h4,
.Auction-Table .Hrs h4,
.Auction-Table .Mins h4,
.Auction-Table .Sec h4 {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
}
.Auction-Table .Days,
.Auction-Table .Hrs,
.Auction-Table .Mins,
.Auction-Table .Sec {
    display: flex;
    align-items: center;
}
.Auction-Table .dot {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
}
.Auction-Table .Days p,
.Auction-Table .Hrs p {
    width: 12px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #212529;
}
.Auction-Table .Mins p {
    width: 13px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #212529;
}
.Auction-Table .Sec p {
    width: 10px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #212529;
}
/* responsive */

@media (max-width: 991px) {
    .Dashboard .custom-Components {
        width: 100%;
        margin-right: 0;
    }
}
@media (max-width: 700px) {
    .Dashboard .Notification-Panel .table .thead-light th,
    .Dashboard .table th:nth-of-type(1),
    .Dashboard .Notification-Panel .table tr th:last-child {
        padding-left: 15px;
    }
    .Notification-Panel {
        padding-bottom: 20px;
    }
}

.MuiTooltip-tooltip > div {
    font-family: var(--fontFamily2);
}
