.checkoutContainer {
    padding: 25px 60px;
    background-color: white;
}
.checkoutPdt {
    width: 100%;
}
.checkoutContainer .checkoutTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    text-align: left;
}
.checkoutContainer .paymentTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}

.checkoutPdt .cardHolder {
    margin-bottom: 30px;
}

.checkoutPdt .adBnkBtns {
    width: 100%;
}

.adBankWrpr.cardContainer {
    padding-left: 0;
    margin-bottom: 25px;
}

.card-1-Container.bankPaymentWrpr,
.card-1-Container.bankPaymentWrpr button {
    width: 100%;
    border-radius: 20px;
    padding: 0;
    margin-bottom: 10px;
}

.card-1-Container.bankPaymentWrpr button h6 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adBankPopup .modal-header button {
    width: 60px;
    height: 60px;
}

.adBankPopup .AddCard-Popup-Banner {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.adBankPopup .Addcard-Popup-Input {
    margin-bottom: 20px;
}

.cstmActSlct .MuiInputBase-input {
    margin-top: 16px;
}

.bankDetailsContainer {
    width: 100%;
}

.bankDetailsContainer .parentCard {
    margin-right: 0;
    width: 100%;
}

.bankDtlCard {
    position: relative;
}

.bankDtlCard .cardsData {
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 15px;
}

.bankDtlCard .vrfyStatus {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bankDtlCard .vrfyStatus button {
    border: 1px solid black;
    width: 130px;
}

@media (max-width: 767px) {
    .checkoutContainer {
        flex-wrap: wrap;
    }
}
@media (max-width: 600px) {
    .bankDtlCard .cardsData p {
        text-align: left;
        width: 100%;
    }
    .bankDtlCard .cardsData p span {
        width: 100px;
    }
    .paymentDetails .paymentCard,
    .paymentDetails .paymentTotal {
        width: 100%;
    }
    .checkoutPdt .cardHolder .checkoutCardCnt {
        flex-wrap: wrap;
    }
    .cardHolder img {
        width: 100%;
    }
    .checkoutContainer .paymentContainer {
        margin-left: 0 !important;
    }
}
