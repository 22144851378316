.custom-container {
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.contact-form {
    width: auto;
    margin: 0 auto;
    max-width: 500px;
}

.bg {
    background-color: rgb(177, 250, 255);
    height: 100vh;
}

.contact-form .MuiFormHelperText-root {
    color: red;
}

.contact-form .customInput .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 30px;
}

.contact-form .customTextArea .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin-bottom: 30px;
}
.custom-container.contactHead {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

@media (max-width: 700px) {
    .custom-container.contactHead {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
}
@media (max-width: 475px) {
    .custom-container.contactHead {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
