body {
    margin: 0;
    font-family: var(--fontFamily1), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--backgroundColor);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

.customContainer {
    padding-left: 60px !important;
    padding-right: 60px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

header {
    box-shadow: 0 2px 10px #00000026;
    /* position: sticky;
    top: 0;
    z-index: 9; */
}

header,
footer {
    background: #fff;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}
.searchCnt {
    border: 1px solid var(--secColor);
    border-radius: 25px;
    margin-left: 50px;
}
.SearchBtn.MuiButtonBase-root.MuiButton-root {
    width: 50px;
    height: 48px;
    border-radius: 50px;
    background-color: var(--secColor);
    min-width: unset;
    color: white;
}
.searchCnt .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.searchCnt .customInput .MuiOutlinedInput-notchedOutline {
    /* margin-bottom: 30px; */
    width: 336px;
    height: 48px;
    border: none;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
}

/* .customInput {
    margin-bottom: 30px;
    } */

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.home .homeCnt,
.search .searchCnt {
    margin-top: 45px;
}

.home .homeLt,
.search .searchLt,
.dashboard .dashboardLt {
    width: 25%;
    margin-right: 30px;
    max-width: 350px;
}

.home .homeRt,
.search .searchRt,
.dashboard .dashboardRt {
    width: -webkit-fill-available;
    max-width: 75%;
}

.home .homeLt .searchInput .input-group-text,
.search .searchLt .searchInput .input-group-text,
.dashboard .dashboardLt .searchInput .input-group-text {
    background-color: #fff;
    border-right: none;
}

.home .homeLt .searchInput input,
.search .searchLt .searchInput input,
.dashboard .dashboardLt .searchInput input {
    border-left: none;
}

.home .homeLt .searchInput input:focus,
.search .searchLt .searchInput input:focus,
.dashboard .dashboardLt .searchInput input:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.home .sectionTitle > h2,
.search .sectionTitle h2 {
    font-size: 26px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    text-align: left;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--primColor) !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.table {
    background: #fff;
}

.table .thead-dark th {
    color: #fff;
    background-color: #2b2b2b;
    border-color: transparent;
    font-size: 15px;
    font-weight: 500;
}

.PaymentModal .modal-dialog.modal-lg {
    max-width: 400px !important;
}

.PaymentModal .helpingText {
    font-size: 18px;
    padding: 20px 0;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
}

.homeSkeletonBody,
.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 30px;
    margin-top: 30px;
}

.homeSkeletonBody .hsbGrid,
.searchSkeletonBody .ssbGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.searchSkeletonBody {
    margin: 45px 0;
}

.loginSkeletonBody {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0;
}

.loginSkeletonBody .lsbLeft {
    margin-right: 15px;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbLeft .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.avatar {
    margin: 0 auto;
}

.loginSkeletonBody .lsbLeft .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody {
    width: 600px;
    margin: 45px auto;
}

.regSkeletonBody .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody .skeleton.title {
    margin: 0 auto;
}

.checkoutSkeletonBody {
    margin: 45px 0;
}

.checkoutSkeletonBody .csbItem {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
    margin-top: 15px;
}

/* input:not(:focus)::-webkit-datetime-edit {
  color: transparent;
} */

/* input::placeholder {
  color: #000;
} */
/* 
input::-webkit-datetime-edit {
  color: #000;
} */

input[value='']::-webkit-datetime-edit {
    color: transparent;
}
input:focus::-webkit-datetime-edit {
    color: #000;
}

button .MuiButton-label {
    font-family: (--fontFamily1);
    font-weight: 600;
}

.naBreadcrumbs.MuiTypography-root {
    margin: 15px 0;
}

.naBreadcrumbs a {
    color: #797979;
}

.slick-slider {
    cursor: grab;
}

.slick-slider:active {
    cursor: grabbing;
}

.pagination-wrapper {
    margin-top: 30px;
}

.pagination-wrapper h6 {
    margin: 0;
}

.actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.actionWrapper > button:first-child {
    margin-right: 30px;
}

.cursorDecoy {
    cursor: pointer;
}

.verifyPhone .phVer {
    display: block;
    margin: 10px 0;
}

.verifyPhone .phVerInp {
    margin-top: 25px;
}

.customModal {
    z-index: 1500 !important;
}

/* .resendSms {
    margin-right: 35px;
} */

.np-pro-box {
    padding: 25px 15px;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.np-pro-box img {
    width: 180px;
}

.np-pro-box h5 {
    margin-top: 20px;
    font-weight: 500;
}

.np-pro-box h6 {
    line-height: 24px;
    font-size: 15px;
    color: #6d6d6d;
}

.hmcustomContainer {
    padding-left: 60px !important;
    padding-right: 60px !important;
    max-width: 1600px;
    margin: auto;
}

.aboutStaticPage .hdrWrpr {
    position: relative;
    margin-bottom: 25px;
}

.aboutStaticPage h4 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0;
    /* background: whitesmoke; */
    width: max-content;
    padding-right: 15px;
}

.aboutStaticPage .hdrWrpr::after {
    content: '';
    width: 100%;
    height: 3px;
    background: var(--secColor);
    position: absolute;
    left: 0;
    top: 47%;
    z-index: -1;
}

.noRecordsFound {
    padding: 15px;
    border: 1px solid #0000001c;
}

.aboutStaticPage .contactWrapper {
    background: var(--secColor);
}

.aboutStaticPage .contactWrapper h4 {
    color: white;
    font-weight: 600;
    text-align: center;
    background: #012150;
    padding-bottom: 20px;
    width: 100%;
}

.aboutStaticPage .contactInfoSection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.aboutStaticPage .lftAdrsDtls .adrsWrp .phone {
    margin: 0;
}

.aboutStaticPage .lftAdrsDtls .adrsWrp .infoClm {
    text-align: center;
}

.aboutStaticPage .lftAdrsDtls .adrsWrp .infoClm p {
    text-align: center;
    color: white;
}

.aboutStaticPage .lftAdrsDtls .adrsWrp .infoClm p a {
    color: white;
}

.aboutStaticPage .lftAdrsDtls .adrsWrp .infoClm .material-icons {
    font-size: 30px;
    color: white;
    margin-bottom: 15px;
}

.aboutStaticPage .gglRvwWrpr {
    margin-top: 80px;
}

.aboutStaticPage .pictureSlider img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    padding: 5px;
}

.aboutStaticPage .hdrWrpr .vwAllBtn {
    color: var(--primColor);
    font-weight: 600;
    /* background: whitesmoke; */
    padding-left: 20px;
}

.aboutStaticPage .slick-list {
    max-height: 460px;
}

.noPdtsFnd {
    padding: 40px 10px;
    color: rgb(179, 179, 179);
    font-weight: 500;
}

.noPdtsFnd .material-icons {
    font-size: 60px;
    color: gainsboro;
}

.emptyTimerDiv {
    height: 44px;
}

.srchVwDtlsCntnr .emptyTimerDiv {
    height: 71px;
}

.upcomingAuction .emptyTimerDiv {
    height: 71px;
}

.mnHtcontnr {
    min-height: 75vh;
}

.intl-tel-input input,
.intl-tel-input input[type='text'],
.intl-tel-input input[type='tel'] {
    margin-top: 16px;
    border: none;
    border-bottom: 1px solid gray;
}

.intl-tel-input .selected-flag {
    display: flex !important;
    align-items: center !important;
}

.intlIptWrpr {
    text-align: left;
    padding-top: 21px;
    position: relative;
}

.intlIptWrpr label {
    text-align: left;
    top: -6px;
    font-size: 12px;
    margin-bottom: 0;
    position: absolute;
    color: #767676;
    font-weight: 600;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    display: table-cell;
    vertical-align: middle;
    padding-left: 4px !important;
}

.intl-tel-input .country-list {
    width: 100%;
}

.intl-tel-input .country-list .country {
    width: 100%;
}

.intl-tel-input .country-list .country-name {
    white-space: pre-line;
}

.MuiAutocomplete-root {
    width: 100%;
}

.MuiAutocomplete-root .MuiInputBase-root {
    padding: 0px 10px !important;
}

.descFldDynTxt > *,
.descFldDynTxt span,
.descFldDynTxt div,
.descFldDynTxt p {
    font-family: var(--fontFamily) !important;
}

.intl-tel-input .country-list {
    z-index: 99;
}

.swal2-container {
    z-index: 10000 !important;
}

.stdlotViewWrapper .timerdiV {
    min-width: 300px;
}
@media (min-width: 1440px) {
    .customContainer {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .home .homeLt,
    .search .searchLt,
    .dashboard .dashboardLt {
        width: 20%;
    }
    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: 80%;
    }
}

@media (max-width: 1460px) {
    .lvActnrRvw iframe {
        width: 100% !important;
    }
}

@media (max-width: 1366px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 280px;
        margin-right: 10px;
    }
}

@media (max-width: 1300px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 32%;
        margin-right: 5px;
    }
    .home .homeLt,
    .search .searchLt,
    .dashboard .dashboardLt {
        width: 32%;
    }
}

@media (max-width: 1024px) {
    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: none;
    }
    .customContainer {
        padding-left: 40px !important;
        padding-right: 40px !important;
        width: 100% !important;
    }
    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        margin-right: 10px;
    }

    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 22px !important;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .home .homeCnt,
    .search .searchCnt {
        flex-wrap: wrap;
    }

    .home .homeLt,
    .search .searchLt {
        width: 100%;
        max-width: none;
        width: 100%;
        max-width: none;
        margin-right: 0;
    }

    .deskFilter {
        display: none;
    }

    .home .hmSearch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .home .hmSearch .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .responsiveFilterDrawer .MuiCollapse-container {
        width: auto;
        min-width: 100%;
        left: 0;
        position: absolute;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        display: inline-flex !important;
        border: none;
        color: var(--primColor);
    }

    .toggleRespDrawer.MuiButtonBase-root .material-icons {
        padding-right: 10px;
    }

    .filterAcc {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin-right: 10px;
        width: 24%;
        position: static;
        margin-top: 0;
    }

    .filterPanel .filterAcc .MuiCollapse-wrapper {
        width: 100%;
    }

    .filterPanel .filterAcc .MuiAccordionSummary-root {
        border: 1px solid #d8d8d8;
        min-height: 50px;
        border-radius: 50px;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        min-height: 300px;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: rgb(174 34 7 / 8%);
        border-color: rgb(174 34 7 / 51%);
    }

    .dashboard .dashboardLt {
        display: none;
    }
    .dashboard .dashboardRt {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }

    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 49%;
    }

    .aboutStaticPage .lftAdrsDtls .adrsWrp {
        margin-top: 20px;
    }

    .aboutStaticPage h4 {
        font-size: 24px;
    }

    .aboutStaticPage .hdrWrpr .vwAllBtn {
        white-space: pre;
    }
}

@media (max-width: 767px) {
    .d-none-xs {
        display: none !important;
    }

    .results-per-page {
        width: 100%;
        margin: 10px 0;
    }

    .rpp-left-wrap {
        margin-right: auto;
    }
    .hmcustomContainer {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .homeSpecialItems {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .homeSpecialItems .specialEvent {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .specialEventLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .seActBtn {
        width: 100%;
        max-width: none;
    }
    .homeSpecialItems .specialEvent .seImg,
    .homeSpecialItems .specialEvent .seContent {
        margin-right: 0;
    }

    .homeSpecialItems .specialEvent .seImg {
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }
    .home .hmSearch .searchInput {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
    }
    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }
    .home .sectionTitle {
        margin-bottom: 20px;
    }
    .filterPanel .filterAcc .MuiAccordionSummary-root {
        min-height: 30px;
        height: 40px;
    }
    .filterPanel .accTitle {
        font-size: 14px;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }
    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        min-height: 65vh;
    }
    .productNav {
        display: none;
    }
    .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
        width: 100% !important;
    }
}

@media (max-width: 500px) {
    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 20px !important;
    }
    .specialEvent .seImg {
        max-width: none !important;
    }
    .specialEvent .seImg img {
        height: 150px !important;
    }
    .pagination-wrapper {
        margin: 20px;
    }
    .pagination-wrapper > div {
        font-size: 12px;
    }

    .pagination-wrapper h6 {
        width: 100%;
        margin-bottom: 15px;
    }

    .pagination-wrapper .MuiPagination-root {
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .filterAcc {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 10px 10px 0;
        width: 47%;
    }
    .responsiveFilterDrawer .MuiCollapse-container {
        top: 120px;
    }
}

@media (max-width: 400px) {
    .homeSpecialItems .specialEvent {
        width: 100%;
    }
    .specialEvent .seImg {
        max-width: none;
    }
    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 18px !important;
    }
    .home .sectionTitle > object {
        width: 25px;
    }
}

@media (max-width: 380px) {
    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 10px 10px 0;
        width: 45%;
    }
}
