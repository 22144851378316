.cardContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 25px;
}

@media (max-width: 600px) {
    .cardContainer .card-1-Container .MuiButton-text,
    .cardContainer {
        padding: 0;
        padding-left: 0px;
    }

    .cardContainer .addNewcard-parent {
        width: 100%;
        margin-bottom: 15px;
    }
}
