.bidSelectVal {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    margin-bottom: 10px;
}

.w-240 {
    width: 50%;
    max-width: 240px;
}

.bidding-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto;
}

.bidding-wrapper .form-control {
    height: 50px;
    padding: 13px;
}

@media (max-width: 500px) {
    .bidding-wrapper .form-control {
        height: 45px;
        padding: 13px;
    }
}
