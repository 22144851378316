* {
    box-sizing: border-box;
}

.Banner {
    display: grid;
    grid-template-columns: 50% 50%;
}

.custom-container-resetpass {
    padding-top: 140px;
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.custom-container-resetpass .coinLogo-resetpassword {
    margin-bottom: 60px;
}

.custom-container-resetpass .resetPassword-text {
    color: #000;
    margin-bottom: 30px;
}

.custom-container-resetpass .resetPassword-text h3 {
    font-weight: 600;
}

.custom-container-resetpass .customInput .MuiFormControl-root {
    margin-bottom: 30px;
}

.custom-container-resetpass .MuiButtonBase-root {
    margin-bottom: 30px;
}

.custom-container-resetpass .MuiButtonBase-root {
    width: 250px;
    text-transform: none;
}

.custom-container-resetpass a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #000;
}

.ResetpassError {
    text-align: left;
    font-size: 12px;
    margin-top: -25px;
    margin-bottom: 25px;
    color: #767676;
    font-weight: 600;
    color: red;
}
.ResetpassError1 {
    color: red;
    text-align: left;
    margin-bottom: 10px;
}

.coinLogo-resetpassword img {
    width: 300px;
}

@media (max-width: 600px) {
    .Banner {
        grid-template-columns: 1fr;
    }

    .custom-container-resetpass {
        padding: 40px !important;
    }
    .Banner .coinImg {
        display: none;
    }
}
