.Banner-carddetails {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: white;
}

.custom-container {
    padding-top: 110px !important;
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.cardDetails-Data {
    padding: 110px 150px 0;
    width: 60%;
}

.Banner-carddetails .bannerImg img {
    width: 100%;
    height: 100%;
}

.cardDetails-Data .cardDetails-Logo {
    margin-bottom: 40px;
}

.cardDetails-Data h3 {
    color: #000;
    font-weight: 600;
    margin-bottom: 30px;
}

.dropDown .MuiInputBase-root.MuiInput-root {
    margin-bottom: 30px;
}

.cardDetails-Data .MuiButtonBase-root {
    width: 190px;
    margin-bottom: 30px;
}

.cardDetails-Data a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #000;
    font-weight: 600;
}

.creditcardnoError {
    color: red;
    text-align: left;
}

.Banner-carddetails .MuiFormHelperText-root,
.signup-page .MuiFormHelperText-root {
    color: red;
    position: relative;
    margin-top: 5px;
    margin-bottom: -15px;
}

.cardDetails-Logo img {
    width: 300px;
}

.registration-inp .errorTxt {
    text-align: left;
    color: red;
    font-size: 0.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-bottom: 18px;
    margin-top: -24px;
}

.cardInput label + .MuiInput-formControl {
    margin-top: 0;
}

.cardInput label.MuiInputLabel-formControl {
    transform: translate(0, 4px) scale(1);
}

.cardInput .dropDown .MuiSelect-select.MuiSelect-select {
    line-height: 32px;
    height: 32px;
    padding: 0;
}

.cardInput label.MuiInputLabel-shrink,
.error-input label.MuiInputLabel-root {
    transform: translate(0, -8px) scale(0.75);
    z-index: 99;
}

.bannerImg {
    width: 40%;
}

.MuiInputBase-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
}
