.addimages input {
    opacity: 0;
    height: 45px;
}
.addimages {
    position: relative;
    margin: 15px 0;
    text-align: left;
    width: 175px;
}
.addimages .choosebtn {
    background: #222;
    text-align: center;
    padding: 12px 15px;
    border-radius: 4px;
    position: absolute;
    top: -2px;
    color: #fff;
    z-index: -1;
    left: 0;
    width: 175px;
    margin-bottom: 15px;
    border: 1px solid #222;
}
