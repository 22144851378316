html {
    background: #fff;
}
.auth-container {
    padding-top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
}

.auth-wrapper {
    width: 100%;
    max-width: 450px;
    padding: 20px;
}

.auth-container-wrapper .logo img {
    max-width: 300px;
    object-fit: contain;
}

.auth-title {
    font-size: 28px;
    font-weight: 600;
    padding: 25px 0;
}

.signup-page .bottomText a {
    border-bottom: 1px solid #000;
    text-decoration: none;
    font-weight: 600;
    color: #021431;
    margin-top: 30px;
    display: inline-block;
}

.signup-page .customInput {
    margin-bottom: 30px;
}
