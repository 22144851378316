.custom-container {
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.login-pageField .logo {
    margin-bottom: 40px;
}

.login-pageField {
    background-color: white;
}

.login-page .login-pageText {
    color: #000;
    margin-bottom: 20px;
}

.login-page .login-pageText h1 {
    font-size: 26px;
    font-weight: 600;
}

.login-page .welcomeText {
    color: #ff3c17;
    margin: 30px 0 30px 0;
}

.login-page .welcomeText h1 {
    font-size: 24px;
    font-weight: 600;
}

.login-page .endInput {
    display: flex;
    margin-bottom: 20px;
    margin-top: 0;
}

.login-pageButton .MuiButton-label {
    text-transform: none;
}

.login-page .bottomText a:link {
    border-bottom: 1px solid #000;
    text-decoration: none;
    font-weight: 600;
}

.login-page .bottomText a {
    color: #021431;
}

.login-page .loginInput .customInput {
    margin-bottom: 30px;
}

.login-pageField .loginInput .MuiInputBase-input {
    padding-bottom: 15px;
}

.login-pageField .login-pageButton .primButton .MuiButtonBase-root {
    width: 191px;
}

.login-page .bottomText {
    margin-top: 30px;
}

/* .coinImg {
    width: 50%;
} */

.login-page .forgotPassword {
    margin: 0 0 0 auto;
    color: #999999;
}

.login-page .forgotPassword a:link {
    text-decoration: none;
}
.login-page .forgotPassword a {
    color: #999999;
}

.login-page .endInput .MuiFormGroup-root .MuiButtonBase-root {
    padding: 0;
    margin-left: 8px;
    color: #999999;
}

.login-page .login-pageformikError {
    color: red;
    font-size: 14px;
}

.invalidInp-1,
.invalidInp-2 {
    font-size: 12px;
    color: red;
    width: 100%;
    text-align: left;
    margin-top: -25px;
    margin-bottom: 15px;
}

/* responsive */

@media (max-width: 630px) {
    .login-pageField.custom-container {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
        padding-top: 100px !important;
    }
    .login-pageField .logo {
        width: 230px;
        height: 65px;
        z-index: 9999;
        display: block;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 150px;
        left: 0;
        right: 0;
        background: white;
        border-radius: 10px;
        padding: 10px;
    }

    .login-pageField .logo img {
        width: 100%;
    }
}
@media (max-width: 580px) {
    .login-pageField.custom-container {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}
@media (max-width: 475px) {
    .login-page .endInput .MuiFormGroup-root .MuiButtonBase-root {
        padding-right: 3px;
        font-size: 14px;
    }
    .login-page .forgotPassword {
        font-size: 14px;
    }
}
