.FavCard-Banner .favoriteCheck {
    margin-left: 80px;
}

.fawWrap {
    width: 300px;
    margin-bottom: 25px;
}

.Favflex {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    justify-content: space-between;
}

.Favbox-Favflex {
    position: absolute;
    z-index: 999;
}

@media (max-width: 600px) {
    .Dashboard .Favourites-cards {
        place-content: center;
    }
}
