.footerCnt {
    background-color: var(--secColor);
    padding-top: 45px;
    padding-bottom: 45px;
}

.footerCnt .footlogo img {
    width: 310px;
}

.footerCnt .lftFtrLgnCntn .pgTgInfoLn {
    color: white;
    font-size: 18px;
    margin-top: 28px;
    text-align: left;
}

.footerCnt .scmlMdaLnks {
    display: flex;
    align-items: center;
    margin-top: 45px;
}

.footerCnt .scmlMdaLnks .mdaWrpr {
    background-color: #0e3d87;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    transition: ease-in-out 0.3s;
}

.footerCnt .scmlMdaLnks .mdaWrpr.fbLnk:hover {
    background: #4267b2;
    border-radius: 100px;
}

.footerCnt .scmlMdaLnks .mdaWrpr.twLnk:hover {
    background: #1da1f2;
    border-radius: 100px;
}

.footerCnt .scmlMdaLnks .mdaWrpr:hover img {
    filter: brightness(20);
}

.footerCnt .scmlMdaLnks .mdaWrpr a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerCnt .scmlMdaLnks .mdaWrpr a img {
    width: 15px;
    height: 15px;
}

.termWrp ul {
    list-style: none;
    padding-left: 0;
    line-height: 45px;
    text-align: left;
}

.termWrp ul li a {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.termWrp .mlInfoTxt {
    line-height: 30px;
    margin-top: 59px;
}

.termWrp .mlInfoTxt a {
    color: #44aa1c;
    text-align: left;
}

.footerCnt .adrsInfoWrpr {
    padding-left: 35px;
    border-left: 1.5px solid #3765ac;
    margin-left: 35px;
}

.footerCnt .adrsInfoWrpr .stnTtle {
    font-size: 16px;
    color: white;
    font-weight: 500;
}

.footerCnt .rtCntIntoWrpr {
    display: flex;
    align-items: center;
}

.footerCnt .adrsInfoWrpr .flxGrdVwRp {
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.footerCnt .adrsInfoWrpr .flxGrdVwRp img {
    margin-right: 20px;
}

.footerCnt .adrsInfoWrpr .flxGrdVwRp p {
    color: white;
}

.footerCnt .adrsInfoWrpr .flxGrdVwRp p a {
    color: #fff;
}

.cpyRtFtr {
    background: #000000;
    padding: 20px 15px;
}

.cpyRtFtr p {
    color: white;
    margin: 0;
    font-weight: 500;
}

.cpyRtFtr p a {
    color: white;
}

.termWrp ul li a button {
    color: #fff;
}

/* responsive css */

@media (max-width: 1080px) {
    .footerCnt .Logo {
        width: 130px;
    }
}

@media (max-width: 991px) {
    .lftFtrLgnCntn {
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .footerCnt .lftFtrLgnCntn .pgTgInfoLn {
        text-align: center;
    }

    .footerCnt .scmlMdaLnks {
        justify-content: center;
    }

    .footerCnt .rtCntIntoWrpr {
        margin: auto;
        margin-top: 60px;
    }

    .termWrp ul {
        text-align: right;
    }

    .termWrp .mlInfoTxt {
        text-align: right !important;
    }
}

@media (max-width: 620px) {
    .footlogo {
        display: block;
        width: 100%;
    }
    .social a {
        padding-left: 10px;
        padding-right: 10px;
    }

    .cpyRtFtr {
        font-size: 12px;
    }

    .cpyRtFtr.pb-76 {
        padding-bottom: 76px;
    }
}

@media (max-width: 580px) {
    .footerCnt .rtCntIntoWrpr {
        flex-wrap: wrap;
    }

    .footerCnt .rtCntIntoWrpr .termWrp {
        width: 100%;
    }

    .termWrp ul {
        text-align: center;
    }

    .termWrp .mlInfoTxt {
        text-align: center !important;
        margin-top: 10px;
    }

    .footerCnt .adrsInfoWrpr {
        margin-left: 0;
        border-left: 0;
        padding-left: 0;
        margin-top: 25px;
        border-top: 1px solid #3765ac;
        width: 100%;
        padding-top: 20px;
    }

    .footerCnt .adrsInfoWrpr .stnTtle {
        text-align: center;
    }
}

@media (max-width: 475px) {
    .termWrp a p {
        margin: 0;
    }

    .termWrp {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }
}
