.filterPanel .fpTitle {
    font-size: 20px;
    color: var(--secColor);
    font-weight: 700;
    text-align: left;
}

.filterPanel .MuiPaper-root.MuiAccordion-root {
    border: none;
    margin-bottom: 5px;
}

.filterPanel .accTitle {
    color: var(--secColor);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.filterTitleCnt {
    margin-bottom: 15px;
    height: 60px;
}

.filterPanel h4 {
    margin-bottom: 0;
}

.filterPanel .filterClear {
    text-transform: none;
    color: #8e8e8e;
    background: transparent;
}

.filterPanel .filterClear .MuiButton-label {
    font-weight: 400;
}

.filterPanel .MuiAccordionDetails-root {
    padding: 10px 16px;
}

.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
    width: 100%;
}

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
    width: 100%;
    margin: 0;
}

.filterPanel .filterCheck .MuiListItem-button {
    padding: 0;
    font-size: 15px;
}

.RadioBox .MuiListItem-button {
    padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
    padding-top: 0;
}

.filterPanelSkeleton {
    /* height: 60vh; */
    margin-bottom: 30px;
}
