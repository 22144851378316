.Dashboard .custom-Components .Won-Auction-Tabs {
    display: flex;
    margin-left: 25px;
}

.Dashboard .custom-Components .Won-Auction-Tabs a:nth-of-type(2) {
    color: red;
    text-transform: none;
    border-bottom: 2px solid red;
}

.Dashboard .custom-Components .Won-Auction-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Won-Auction-Tabs a {
    margin-right: 50px;
    font-weight: 600;
    color: #a8a7a7;
}

.Dashboard .wonAuction-Table .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
    width: 102px;
}

.Dashboard .wonAuction-Table .Paid {
    color: #008b25;
}

.Dashboard .wonAuction-Table .Unpaid {
    color: #e18700;
}

.wonAuction-Table .primButton .MuiButtonBase-root {
    height: 26px;
    width: 102px;
    padding-top: 7px;
}

.Dashboard .won-pgn {
    width: max-content;
    margin: 20px auto;
}
.Dashboard .wonAuction-Table .das-togle-switch .MuiButtonBase-root {
    justify-content: normal;
}
