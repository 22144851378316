.Dashboard {
    display: flex;
    text-align: center;
    background-color: white;
}
.Dashboard .table td,
.Dashboard .table th {
    text-align: left;
}
.custom-container-Dashboard {
    margin: 0 auto 20px !important;
    padding-top: 20px !important;
    width: 100% !important;
    max-width: 1280px;
}

.Dashboard .Tabs {
    margin-right: 20px;
    padding-top: 40px;
    padding-bottom: 95px;
    width: 248px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px 6px 0px 0px;
}

.Dashboard .Tabs h4 {
    font-family: var(--fontFamily2);
    font-size: 18px;
    font-weight: 500;
}

.Dashboard .Tabs img {
    margin-bottom: 18px;
}

.Dashboard .Tabs .Tabs-Items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}

.Dashboard .Tabs .Tabs-Items ul {
    padding: 0;
    margin: 0;
    text-align: left;
}

.Dashboard .Tabs .Tabs-Items li {
    height: 53px;
    list-style-type: none;
    font-weight: 800;
    padding: 17px 114px 17px 59px;
}

.Dashboard .Tabs .Tabs-Items ul:hover {
    cursor: pointer;
}

.Dashboard .Tabs .Tabs-Items .Active {
    border-left: 4px solid rgb(199, 0, 0);
    background-image: linear-gradient(to right, rgb(255, 211, 211), #ffffff);
    z-index: 100;
}

.Dashboard .custom-Components {
    padding: 30px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px 6px 0px 0px;
}
.Dashboard .MuiBox-root {
    padding: 25px 0px 25px 0px;
}
.Dashboard .custom-Components .Active-Auction-Tabs {
    display: flex;
}

.Dashboard .custom-Components .Active-Auction-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Active-Auction-Tabs a {
    margin-right: 20px;
    font-weight: 600;
}

.Dashboard .custom-Components .Active-Auction-Sub-Tabheader {
    background-color: #ececec;
    height: 50px;
    padding: 12px 0 0 25px;
}

.Dashboard .custom-Components .Active-Auction-Sub-Tabheader a {
    margin-right: 25px;
    font-weight: 600;
}

.Dashboard
    .custom-Components
    .Dashboard-Tabpanel
    .MuiButtonBase-root
    .Mui-selected
    .MuiTab-wrapper {
    color: red;
}

.Dashboard .Tabs-Items a {
    text-decoration: none;
    color: black;
}

.Dashboard .Tabs-Items a:link {
    text-decoration: none;
    display: inline-block;
    width: 180px;
    text-align: left;
    font-family: var(--fontFamily2);
    font-weight: 400;
}

.Dashboard .Tabs img {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    /* margin-right: 35px; */
}
.dashNav {
    display: none;
}

.frstHdrRow {
    font-weight: normal;
}

.Dashboard .table .vwBtnWrpr button {
    font-weight: 600;
    width: 106px;
    height: 40px;
    text-transform: none;
}

.fav-head {
    color: var(--primColor) !important;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: left;
}

.favourites-cards .Favbox-Favflex {
    right: 18px;
}

.vwBtnWrpr .secButton + .secButton {
    margin-left: 10px;
}
/* responsive css */

@media (max-width: 991px) {
    .cardContainer .card-1-Container .MuiButton-text {
        padding: 0px 8px;
    }
    .Dashboard .Dashboard-Tabpanel .MuiBox-root {
        padding: 25px 0px 25px 0px;
    }
    .cardContainer {
        margin: auto;
        text-align: center;
        align-items: flex-start;
        /* justify-content: center; */
    }
    .Paymentab-Dashboard-Tabpanel .MuiTab-root {
        padding: 0px 12px;
    }
    .Dashboard .MuiBox-root {
        padding: 25px 25px 25px 25px;
    }
    .userProfileimg img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        max-width: 150px;
    }
    .userProfileimg p {
        font-size: 13px;
        color: #ccc;
        margin: auto;
    }
    .userProfileimg .profileInf {
        padding: 5px 15px;
    }
    .mobFilter {
        padding: 0;
    }
    .userProfileimg h4 {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 3px;
    }
    .userProfileimg {
        text-align: left;
        padding: 8px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background: #fff;
    }
    .mobFilter .MuiButtonBase-root.Active {
        border-left: 4px solid rgb(199, 0, 0);
    }
    .mobFilter .MuiButtonBase-root {
        padding: 12px 10px;
    }
    .mobFilter .MuiButtonBase-root a {
        color: #757575;
        display: block;
        width: 100%;
    }
    .mobFilter .MuiListItemIcon-root {
        min-width: 35px;
    }
    .mobFilter .MuiListItemIcon-root i {
        font-size: 20px;
    }
    .Dashboard .custom-Components {
        padding-top: 0;
    }
    .Dashboard .Tabs {
        display: none;
    }
    .Dashboard .custom-Components {
        width: 100%;
        margin-right: 0;
    }
    .Dashboard .custom-Components .Dashboard-Tabpanel .MuiTabs-root {
        margin-left: auto;
    }
    .Dashboard-Tabpanel {
        padding-left: 40px;
        padding-right: 40px;
    }
    .dashNav {
        display: block;
        width: auto;
        max-width: 150px;
        background: var(--primColor);
        margin: 15px 25px;
    }
    .dashNav button {
        color: #fff;
        padding: 10px;
        width: 100%;
    }
    .dashNav button span {
        padding-right: 6px;
    }
    .socialLinks p {
        font-size: 12px;
        margin: 0;
    }
    .socialLinks {
        background: white;
        display: flex;
        padding: 10px 12px;
        position: fixed;
        width: 250px;
        bottom: 0;
        border-top: 1px solid #e0e0e0;
        align-items: center;
        justify-content: space-around;
    }
}
@media (max-width: 767px) {
    .Dashboard .custom-Components {
        padding: 15px;
    }
    .cardContainer .card-1-Container .MuiButton-text {
        padding: 5px 8px;
    }

    .lotPdtWrp .table th {
        max-width: 100px !important;
        min-width: 100px !important;
    }

    .lotPdtWrp.customContainer {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .lotTime .Days,
    .lotTime .Hrs,
    .lotTime .Mins,
    .lotTime .Sec,
    .productCntWrp .timerCnt h4 {
        font-size: 14px !important;
    }
    .lotTime .Days p,
    .lotTime .Hrs p,
    .lotTime .Mins p,
    .lotTime .Sec p,
    .productCntWrp .timerCnt p {
        font-size: 9px !important;
        margin-right: 3px !important;
    }

    .cardContainer {
        margin: auto;
        text-align: center;
        align-items: flex-start;
        justify-content: center;
        padding-left: 0;
    }
    .payment--Table .table tr td.border-none,
    .lotPdtWrp .table tr td.border-none,
    .Buynow--Table .table tr td.border-none,
    .LostAuction-Table .table tr td.border-none,
    .wonAuction-Table .table tr td.border-none,
    .Auction-Table .table tr td.border-none {
        /* width: calc(40vw - 40px);
        height: auto;
        min-width: calc(40vw - 40px); */
    }
    .Dashboard .Dashboard-Tabpanel .MuiButtonBase-root {
        width: auto;
        min-width: auto;
    }

    .Dashboard .MuiTab-wrapper,
    .Dashboard .table .thead-light th {
        font-size: 14px;
        padding: 14px 0;
        border-bottom: 1px solid #dcdcdc;
    }
    .lotPdtWrp .table tr td.border-none .card,
    .Buynow--Table .table tr td.border-none .card,
    .LostAuction-Table .table tr td.border-none .card,
    .wonAuction-Table .table tr td.border-none .card,
    .Auction-Table .table tr td.border-none .card {
        /* margin-top: 0 !important; */
    }
    .payment--Table .table td,
    .payment--Table .table th,
    .lotPdtWrp .table td,
    .lotPdtWrp .table th,
    .Buynow--Table .table th,
    .Buynow--Table .table td,
    .Auction-Table .table th,
    .LostAuction-Table .table td,
    .LostAuction-Table .table th,
    .wonAuction-Table .table td,
    .wonAuction-Table .table th,
    .Auction-Table .table td {
        /* text-align: center;
        padding: 8px; */
    }
    .payment--Table .table,
    .lotPdtWrp .table,
    .Buynow--Table .table,
    .LostAuction-Table .table,
    .wonAuction-Table .table,
    .Auction-Table .table {
        /* display: block; */
        /* width: 100%; */
    }
    /* .Auction-Table:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    } */
    .payment--Table .table thead,
    .payment--Table .table tbody,
    .payment--Table .table thead th,
    .lotPdtWrp .table thead,
    .lotPdtWrp .table tbody,
    .lotPdtWrp .table thead th,
    .Buynow--Table .table thead,
    .Buynow--Table .table tbody,
    .Buynow--Table .table thead th,
    .LostAuction-Table .table thead,
    .LostAuction-Table .table tbody,
    .LostAuction-Table .table thead th,
    .wonAuction-Table .table thead,
    .wonAuction-Table .table tbody,
    .wonAuction-Table .table thead th,
    .Auction-Table .table thead,
    .Auction-Table .table tbody,
    .Auction-Table .table thead th {
        display: block;
    }
    .payment--Table .table thead th:last-child,
    .lotPdtWrp .table thead th:last-child,
    .Buynow--Table .table thead th:last-child,
    .LostAuction-Table .table thead th:last-child,
    .wonAuction-Table .table thead th:last-child,
    .Auction-Table .table thead th:last-child {
        /* border-bottom: none; */
    }
    .payment--Table .table thead,
    .lotPdtWrp .table thead,
    .Buynow--Table .table thead,
    .LostAuction-Table .table thead,
    .wonAuction-Table .table thead,
    .Auction-Table .table thead {
        /* float: left; */
    }
    .payment--Table .table tbody,
    .lotPdtWrp .table tbody,
    .Buynow--Table .table tbody,
    .LostAuction-Table .table tbody,
    .wonAuction-Table .table tbody,
    .Auction-Table .table tbody {
        /* width: auto;
        position: relative;
        overflow-x: auto;
        max-width: calc(100vw - 80px); */
        border: 1px solid #f0f0f0;
    }
    .payment--Table .table th,
    .payment--Table .table td,
    .lotPdtWrp .table th,
    .lotPdtWrp .table td,
    .Buynow--Table .table th,
    .Buynow--Table .table td,
    .LostAuction-Table .table th,
    .LostAuction-Table .table td,
    .wonAuction-Table .table th,
    .wonAuction-Table .table td,
    .Auction-Table .table td,
    .Auction-Table .table th {
        /* padding: 20px 0.625em 0.625em 0.625em;
        height: 50px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: auto;
        min-width: 130px;
        max-width: fit-content;
        font-size: 13px;
        text-overflow: ellipsis; */
    }
    .payment--Table .table thead th,
    .lotPdtWrp .table thead th,
    .Buynow--Table .table thead th,
    .LostAuction-Table .table thead th,
    .wonAuction-Table .table thead th,
    .Auction-Table .table thead th {
        /* text-align: left;
        border-bottom: 1px solid #f7f7f9;
        padding: 14px 10px;
        padding-left: 10px !important; */
        display: none;
    }
    .payment--Table .table tbody tr,
    .lotPdtWrp .table tbody tr,
    .Buynow--Table .table tbody tr,
    .LostAuction-Table .table tbody tr,
    .wonAuction-Table .table tbody tr,
    .Auction-Table .table tbody tr {
        /* display: table-cell;
        vertical-align: top; */
        margin-bottom: 5px;
    }
    .payment--Table .table tbody tr:nth-child(odd),
    .lotPdtWrp .table tbody tr:nth-child(odd),
    .Buynow--Table .table tbody tr:nth-child(odd),
    .LostAuction-Table .table tbody tr:nth-child(odd),
    .wonAuction-Table .table tbody tr:nth-child(odd),
    .Auction-Table .table tbody tr:nth-child(odd) {
        /* background: none; */
    }
    .payment--Table .table tr:nth-child(even),
    .lotPdtWrp .table tr:nth-child(even),
    .Buynow--Table .table tr:nth-child(even),
    .LostAuction-Table .table tr:nth-child(even),
    .wonAuction-Table .table tr:nth-child(even),
    .Auction-Table .table tr:nth-child(even) {
        /* background: transparent; */
    }
    .payment--Table .table tr td:nth-child(odd),
    .lotPdtWrp .table tr td:nth-child(odd),
    .Buynow--Table .table tr td:nth-child(odd),
    .LostAuction-Table .table tr td:nth-child(odd),
    .wonAuction-Table .table tr td:nth-child(odd),
    .Auction-Table .table tr td:nth-child(odd) {
        /* background: #0e3e872e;
        border-right: 1px solid #e6e4e4; */
    }
    .payment--Table .table tr td:nth-child(even),
    .lotPdtWrp .table tr td:nth-child(even),
    .Buynow--Table .table tr td:nth-child(even),
    .LostAuction-Table .table tr td:nth-child(even),
    .wonAuction-Table .table tr td:nth-child(even),
    .Auction-Table .table tr td:nth-child(even) {
        /* border-right: 1px solid #d4dce9; */
    }
    .payment--Table .table tbody td::before,
    .lotPdtWrp .table tbody td::before,
    .Buynow--Table .table tbody td::before,
    .LostAuction-Table .table tbody td::before,
    .wonAuction-Table .table tbody td::before,
    .Auction-Table .table tbody td::before {
        content: attr(data-title);
        font-weight: 600;
        width: 120px;
        min-width: 120px;
        color: black;
        font-size: 14px;
    }
    .payment--Table .table tbody td,
    .lotPdtWrp .table tbody td,
    .Buynow--Table .table tbody td,
    .LostAuction-Table .table tbody td,
    .wonAuction-Table .table tbody td,
    .Auction-Table .table tbody td {
        /* display: flex;
        text-align: center;
        align-items: center;
        padding: 6px 10px !important; */
        /* min-width: 190px; */
        display: flex;
        border: 1px solid #f0f0f0;
        font-size: 14px;
    }
    .payment--Table .table tbody td.border-none,
    .LostAuction-Table .table tbody td.border-none,
    .lotPdtWrp .table tbody td.border-none,
    .wonAuction-Table .table tbody td.border-none,
    .Buynow--Table .table tbody td.border-none,
    .Auction-Table .table tbody td.border-none {
        /* display: inherit;
        width: 100%; */
    }
}
@media (max-width: 600px) {
    .wonAuction-Table .table tbody td[data-title='checkBtn']::before {
    }
    .wonAuction-Table .table tbody td[data-title='checkBtn']::before {
        display: none;
    }
    .Dashboard .table td:nth-last-child(1) button {
        width: 100%;
    }
    .Dashboard .Notification-Panel .table tbody th:nth-of-type(1) {
        min-width: auto;
    }
    .productCntWrp .timerCnt p {
        font-size: 18px !important;
    }
    .Dashboard .table tbody th:nth-of-type(1) {
        min-width: 180px;
    }
    .Dashboard-Tabpanel {
        padding-left: 10px;
        padding-right: 10px;
    }
    .dashNav {
        margin: 15px 20px;
    }
}
@media (max-width: 500px) {
    .Buynow--Table .table tr td.border-none,
    .LostAuction-Table .table tr td.border-none,
    .wonAuction-Table .table tr td.border-none,
    .Auction-Table .table tr td.border-none {
        min-width: calc(50vw - 40px);
    }
}
@media (max-width: 425px) {
    .Dashboard .Dashboard-Tabpanel .MuiButtonBase-root .MuiTab-wrapper {
        padding: 4px;
        border: 1px solid #d6d6d6;
    }
    .Dashboard .MuiTab-wrapper,
    .Dashboard .table .thead-light th {
        font-size: 13px;
    }
    .Dashboard-Tabpanel .MuiTabs-flexContainer {
        flex-wrap: wrap;
    }
    .Dashboard .Dashboard-Tabpanel .MuiButtonBase-root {
        font-weight: 500;
        width: 32%;
        height: auto;
        padding: 0;
        min-height: auto;
        margin: 3px 1px;
    }
}
@media (max-width: 345px) {
    .Dashboard .MuiTab-wrapper,
    .Dashboard .table .thead-light th {
        font-size: 12px;
    }
}
